import './navigation-bar.component.scss';

import AddImage from '@icons/add-image.svg';
import AllImages from '@icons/all-images.svg';
import Home from '@icons/home.svg';
import { A, useMatch } from '@solidjs/router';
import { For } from 'solid-js';

export function NavigationBar() {
  const items = [{
    route: '/',
    icon: <Home />
  }, {
    route: '/upload',
    icon: <AddImage />
  }, {
    route: '/gallery',
    icon: <AllImages />
  }].map(({route, icon }) => ({
    route,
    icon,
    isActive: useMatch(() => route)
  }));

  return <div class="navigation-bar glass">
    <For each={items}>
      {item => <>
        <div class={item.isActive() ? 'active' : ''}>
          <A href={item.route}>
            <button>
              {item.icon}
            </button>
          </A>
        </div>
      </>}
    </For>
  </div>;
}