import { MediaApi, MediaModel } from '@api/api';
import { ProgressInfo } from '@codewyre/wyrekit-runtime';
import { inject, injectable } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import { Image } from '../components/images/image.view-model';
import { UserModel } from '../models/user.model';

@injectable()
export class MediaService {
 //#region Properties
  private _listChangedLastAt = new BehaviorSubject<number>(Date.now());
  public get listChangedLastAt(): Observable<number> {
    return this._listChangedLastAt.asObservable();
  }
  //#endregion

  //#region Constructor
  public constructor(
    @inject(MediaApi)
    private readonly _mediaApi: MediaApi) { }
  //#endregion

  //#region Public Methods
  public triggerUpdate(): void {
    this._listChangedLastAt.next(Date.now());
  }

  public uploadFiles(user: UserModel, files: File[]): {
    progress: Observable<ProgressInfo>,
    request: Promise<void>
  } {
    const progress = new BehaviorSubject<ProgressInfo>({
      current: 0,
      total: 0
    });

    const request = this._mediaApi.addMedia(
      user.id,
      user.token,
      files, {
      onUploadProgress: (progressEvent) =>
        progress.next({
          current: progressEvent.loaded || 0,
          total: progressEvent.total || 0
        })
    });

    return {
      progress,
      request: request.then(() => undefined)
    };
  }

  public async getMyFiles(user: UserModel): Promise<MediaModel[]> {
    const { data } = await this._mediaApi.getMediaListForUser(user.id, user.token);
    return data;
  }


  public async getAllFiles(): Promise<MediaModel[]> {
    const { data } = await this._mediaApi.getMediaList();
    return data;
  }

  public async deleteFile(user: UserModel, image: Image) {
    await this._mediaApi.deleteMediaFile(user.id, image.id!, user.token);
  }
  //#endregion
}