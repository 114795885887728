import './images.component.scss';

import { For, Show } from 'solid-js';

import { ImageWrapper } from './image-wrapper.component';
import { Image } from './image.view-model';

export interface ImagesProps {
  images: Image[];
  onRemove?: (image: Image) => void;
  showSize?: boolean;
}

export function Images(props: ImagesProps) {
  const images = () => props.images;

  return <>
    <Show when={!images().length}>
      <div class="flex"></div>
    </Show>
    <Show when={images().length}>
      <div class="glass__content glass__content--scrollable images d-flex jc-center g-8 flex fw-wrap mb-16">
        <For each={images()}>
          {image => <ImageWrapper
            image={image}
            images={images()}
            showSize={props.showSize}
            onRemove={props.onRemove}
            isPreview={true} />}
        </For>
      </div>
    </Show>
  </>
}