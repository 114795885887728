import './uploaded.view.scss';

import { Configuration } from '@api/configuration';
import { MediaService } from '@app/services/media.service';
import { UserService } from '@app/services/user.service';
import { createFromObservable } from '@codewyre/wyrekit-solid';
import ArrowBackIcon from '@icons/arrow-back.svg';
import { A } from '@solidjs/router';
import { concat, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { createSignal, Show } from 'solid-js';

import { Image } from '../../../../components/images/image.view-model';
import { Images } from '../../../../components/images/images.component';
import { LoadingIndicator } from '../../../../components/loading-indicator/loading-indicator.component';
import { SecurityService } from '../../../../services/security.service';
import { useDependency } from '../../../../shared';

export function UploadedView() {
  const userService = useDependency<UserService>(UserService);
  const configuration = useDependency<Configuration>(Configuration);
  const mediaService = useDependency<MediaService>(MediaService);
  const securityService = useDependency<SecurityService>(SecurityService);
  const [user] = createFromObservable(userService.user);
  const [loading, setLoading] = createSignal(true);
  const [images] = createFromObservable(
    concat(
      of([]),
      mediaService
        .listChangedLastAt
        .pipe(
          tap(() => setLoading(true)),
          switchMap(() => userService.user),
          switchMap(async (user) => {
            if (!user) {
              return [];
            }

            const media = await mediaService.getMyFiles(user!);
            return await Promise.all(media.map(async (x) => ({
              id: x.id,
              createdBy: x.createdBy,
              file: {
                type: x.type
              },
              url: Promise.resolve(
                `${configuration.basePath}/media/${user.id}/${x.type.startsWith('video') ? 'stream' : 'download'}/${x.id}?passcode=${securityService.getPasscode()}`
              ),
              thumbUrl: Promise.resolve(
                `${configuration.basePath}/media/${user.id}/${x.type.startsWith('video') ? 'stream' : 'download'}/${x.id}?type=thumb&passcode=${securityService.getPasscode()}`
              )
            } as Image) ))
          }),
          tap(() => setLoading(false)))));


  return <Show when={user()}>
    <div class="uploaded p-16 ta-center d-flex ff-column flex">
      <div class="content p-32 glass  d-flex ff-column flex">
        <div>
          <A href="/">
            <div class="small-link d-flex ai-center no-font g-8 text-shadowed">
              <ArrowBackIcon /> Zurück
            </div>
          </A>
        </div>
        <div>
          <h3 class="text-bold">
            Deine bisher hochgeladenen Erinnerungen
          </h3>
        </div>
        <Show when={!loading()}>
          <Images images={images()} />
          <Show when={images().length}>
            <div>
              <div class="mb-24">
                <A href='/upload'>
                  <button>Weitere hochladen</button>
                </A>
              </div>
            </div>
          </Show>
        </Show>
        <Show when={!loading() && !images().length}>
          <div class="mt-16">
            <div class="mb-24">
              Du hast bisher keine Bilder oder Videos hochgeladen.
            </div>
            <div class="mb-24">
              <A href='/upload'>
                <button>Jetzt hochladen!</button>
              </A>
            </div>
          </div>
        </Show>
        <Show when={loading()}>
          <div class="mt-128"><LoadingIndicator /></div>
          <div>Lade Bilder & Videos...</div>
          <div class="flex"></div>
        </Show>
      </div>
    </div>
  </Show>
}