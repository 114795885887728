import './size-progress-bar.scss';

import { createEffect, Show } from 'solid-js';

export interface SizeProgressBarStats {
  used: number;
  remaining: number;
  overweight: number;
  calculatedMaximum: number;
  maximum: number;
}

export interface SizeProgressBarProps {
  maximum: number;
  files: File[];
  onStatsUpdated?: (stats: SizeProgressBarStats) => void
}

export function SizeProgressBar(props: SizeProgressBarProps) {
  const max = () => props.maximum * 1024 * 1024;
  const used = () => props.files.reduce((prev, cur) => prev + cur.size, 0);
  const remaining = () => Math.max(0, max() - used());
  const overweight = () => Math.max(0, used() - max());
  const calculatedMaximum = () => Math.max(used(), max());

  const percentUsed = () => (Math.min(max(), used()) / calculatedMaximum()) * 100;
  const percentRemaining = () => remaining() / calculatedMaximum() * 100;
  const percentOverweight = () => overweight() / calculatedMaximum() * 100;

  createEffect(() => {
    if (!props.onStatsUpdated) {
      return;
    }

    props.onStatsUpdated({
      calculatedMaximum: calculatedMaximum(),
      used: used(),
      remaining: remaining(),
      overweight: overweight(),
      maximum: max()
    });
  });

  return <div class="size-progress-bar d-flex">
    <div
      class="size-progress-bar__used"
      style={{ width: percentUsed() + '%' }}>
        {Math.floor(used() / 1024 / 1024)} MB gesamt
    </div>
    <Show when={remaining() > 0}>
      <div
        class="size-progress-bar__remaining"
      style={{ width: percentRemaining() + '%' }}>
        {Math.floor(remaining() / 1024 / 1024)} MB verbleibend
      </div>
    </Show>
    <Show when={overweight() > 0}>
      <div
        class="size-progress-bar__overweight"
      style={{ width: percentOverweight() + '%' }}>
        {Math.floor(overweight() / 1024 / 1024)} MB zuviel!
      </div>
    </Show>
  </div>
}