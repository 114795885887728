import { BaseAPI } from '@api/base';
import { Configuration } from '@api/configuration';
import { interfaces } from 'inversify';

export function apiFactory<T extends BaseAPI>(
  type: interfaces.Newable<T>,
  configuration: Configuration) {
  return (context: interfaces.Context) => {
    const apiType = type as typeof BaseAPI;

    return new apiType(configuration);
  };
}