import './image-overlay.component.scss';

import { createFromObservable, OverlayService } from '@codewyre/wyrekit-solid';
import Bin from '@icons/bin.svg';
import Close from '@icons/close.svg';
import ChevronLeft from '@icons/left.svg';
import ChevronRight from '@icons/right.svg';
import { createSignal, Show } from 'solid-js';

import { UserService } from '../../../services/user.service';
import { useDependency } from '../../../shared';
import { ImageWrapper } from '../image-wrapper.component';
import { Image } from '../image.view-model';
import {
  ImageOverlayConfirmDeletionComponent,
  ImageOverlayConfirmDeletionComponentProps,
} from './image-overlay.confirm-deletion';

export interface ImageOverlayProps {
  image: Image;
  imageList: Image[];
  showSize?: boolean;
  onRemove?: (image: Image) => void;
}

export function ImageOverlayComponent(props: ImageOverlayProps) {
  function getSibling(imageList: Image[], image: Image, step: number) {
    const index = imageList.indexOf(image);
    if (step < 0 && index <= 0 || step > 0 && index >= imageList.length - 1) {
      return null;
    }

    return imageList[index + step];
  }

  const image = () => props.image;
  const showSize = () => props.showSize === undefined ? false : props.showSize;
  const imageList = () => props.imageList;

  const previous = () => !imageList().length
    ? null
    : getSibling(imageList(), props.image, -1);

  const next = () => !imageList().length
    ? null
    : getSibling(imageList(), props.image, 1);

  const overlayService = useDependency<OverlayService>(OverlayService);
  const userService = useDependency<UserService>(UserService);
  const [user] = createFromObservable(userService.user);
  const [controlsActive, setControlsActive] = createSignal(true);

  // FIX: sad
  function deleteImage(image: Image): void {
    overlayService.show<ImageOverlayConfirmDeletionComponentProps>({
      component: ImageOverlayConfirmDeletionComponent,
      props: {
        image,
        fallback: previous() ?? next() ?? null,
        images: [...imageList()],
        onRemove: props.onRemove
      }
    });
  }

  function show(nextImage: Image | null) {
    if (!nextImage) {
      return;
    }
    overlayService.close();
    overlayService.show({
      component: ImageOverlayComponent,
      props: {
        image: nextImage,
        imageList: imageList(),
        onRemove: props.onRemove,
        showSize: props.showSize
      }
    });
  }

  return <div class="image-overlay ai-center d-flex jc-center ff-column g-16 pt-64 pb-32" onClick={() => setControlsActive(!controlsActive())}>
    <div class={'ta-center ' + (controlsActive() ? '' : "hide-image-overlays")}>
      <ImageWrapper
        image={image()}
        showTypeIcon={false}
        isPreview={false}
        images={imageList()} />
    </div>
    <div class="flex span"></div>
    <Show when={controlsActive()}>
      <Show when={image().createdBy}>
        <div class="author ta-center text-shadowed">
          <Show when={showSize()}>
            <div>
              <div class="weak">
                Größe
              </div>
              <div>{Math.round(image().size / 1024 / 1024)} MB</div>
            </div>
          </Show>
          <div>
            <div class="weak">
              Hochgeladen von
            </div>
            <div>{image().createdBy.name}</div>
          </div>
        </div>
      </Show>
      <div class="actions d-flex w-100 d-flex g-16 p-32 bs-bb">
        <div class='actions__left d-flex jc-start'>
          <div>
            <Show when={previous()}>
              <button class="glass previous" onClick={() => show(previous())}>
                <ChevronLeft />
              </button>
            </Show>
          </div>
        </div>
        <div class="actions__center flex d-flex ai-start jc-center g-16">
          <Show when={image().createdBy}>
            <div class="author ta-center text-shadowed">
              <Show when={showSize()}>
                <div>
                  <div class="weak">
                    Größe
                  </div>
                  <div>{Math.round(image().size / 1024 / 1024)} MB</div>
                </div>
              </Show>
              <div>
                <div class="weak">
                  Hochgeladen von
                </div>
                <div>{image().createdBy.name}</div>
              </div>
            </div>
          </Show>
          <Show when={!image().id || image().id && user() && user()?.id === image().createdBy.id}>
            <div class="ta-center">
              <button class="glass error delete" onClick={() => deleteImage(image())}>
                <Bin />
              </button>
            </div>
          </Show>
          <div class="ta-center">
            <button class="close glass" onClick={() => overlayService.close()}>
              <Close />
            </button>
          </div>
        </div>
        <div class="actions__right d-flex jc-end">
          <div>
            <Show when={next()}>
              <button  class="next glass" onClick={() => show(next())}>
                <ChevronRight />
              </button>
            </Show>
          </div>
        </div>
      </div>
    </Show>
  </div>
}