import { HealthApi } from '@api/api';
import { inject, injectable } from 'inversify';

@injectable()
export class SecurityService {
  public constructor(
    @inject(HealthApi)
    private readonly healthApi: HealthApi) { }

  public async validateAndStoreCode(code: string): Promise<boolean> {
    let validationResult = false;
    try {
      this.setCode(code);
      const result = await this.healthApi.getHealthStatus();
      validationResult = result.status !== 403;
    } catch (error) {
      if ((error as any).response.status === 403) {
        validationResult = false;
      } else {
        throw error;
      }
    }

    if (validationResult) {
      return validationResult;
    }

    this.removeCode();

    return validationResult;
  }

  public getPasscode() {
    return localStorage.getItem('passcode');
  }

  public setCode(passcode: string): void {
    localStorage.setItem('passcode', passcode);
  }

  public removeCode(): void {
    localStorage.removeItem('passcode');
  }

  public hasCode(): boolean {
    return !!localStorage.getItem('passcode');
  }
}