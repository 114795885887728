/* tslint:disable */
/* eslint-disable */
/**
 * @codewyre/image-gallery
 * Web Application for having guests upload images.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: hobart2967@outlook.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CreateUserRequestModel
 */
export interface CreateUserRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetHealthStatus200Response
 */
export interface GetHealthStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof GetHealthStatus200Response
     */
    'status': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MediaFileCharacter = {
    Thumb: 'thumb',
    Original: 'original',
    Preview: 'preview'
} as const;

export type MediaFileCharacter = typeof MediaFileCharacter[keyof typeof MediaFileCharacter];


/**
 * 
 * @export
 * @interface MediaModel
 */
export interface MediaModel {
    /**
     * 
     * @type {string}
     * @memberof MediaModel
     */
    'id': string;
    /**
     * 
     * @type {UserModel}
     * @memberof MediaModel
     */
    'createdBy': UserModel;
    /**
     * 
     * @type {string}
     * @memberof MediaModel
     */
    'file': string;
    /**
     * 
     * @type {string}
     * @memberof MediaModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof MediaModel
     */
    'originalFileName': string;
    /**
     * 
     * @type {string}
     * @memberof MediaModel
     */
    'created': string;
    /**
     * 
     * @type {MediaModelStats}
     * @memberof MediaModel
     */
    'stats': MediaModelStats;
}
/**
 * 
 * @export
 * @interface MediaModelStats
 */
export interface MediaModelStats {
    /**
     * 
     * @type {MediaStatsModel}
     * @memberof MediaModelStats
     */
    'original': MediaStatsModel;
    /**
     * 
     * @type {MediaStatsModel}
     * @memberof MediaModelStats
     */
    'thumbnail': MediaStatsModel | null;
}
/**
 * 
 * @export
 * @interface MediaStatsModel
 */
export interface MediaStatsModel {
    /**
     * 
     * @type {number}
     * @memberof MediaStatsModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaStatsModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaStatsModel
     */
    'size'?: number;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickUserModelExcludeKeyofUserModelToken
 */
export interface PickUserModelExcludeKeyofUserModelToken {
    /**
     * 
     * @type {string}
     * @memberof PickUserModelExcludeKeyofUserModelToken
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PickUserModelExcludeKeyofUserModelToken
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'token': string;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHealthStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.getHealthStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthStatus(options?: any): AxiosPromise<GetHealthStatus200Response> {
            return localVarFp.getHealthStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getHealthStatus(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).getHealthStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedia: async (userId: string, token: string, files: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addMedia', 'userId', userId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('addMedia', 'token', token)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('addMedia', 'files', files)
            const localVarPath = `/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (userId !== undefined) { 
                localVarFormParams.append('userId', userId as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.append('token', token as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaFile: async (userId: string, mediaId: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMediaFile', 'userId', userId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('deleteMediaFile', 'mediaId', mediaId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('deleteMediaFile', 'token', token)
            const localVarPath = `/media/{userId}/{mediaId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {MediaFileCharacter} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFile: async (userId: string, mediaId: string, type?: MediaFileCharacter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMediaFile', 'userId', userId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('getMediaFile', 'mediaId', mediaId)
            const localVarPath = `/media/{userId}/download/{mediaId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/media/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaListForUser: async (userId: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMediaListForUser', 'userId', userId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getMediaListForUser', 'token', token)
            const localVarPath = `/media/{userId}/list`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartialMediaFile: async (userId: string, mediaId: string, range?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPartialMediaFile', 'userId', userId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('getPartialMediaFile', 'mediaId', mediaId)
            const localVarPath = `/media/{userId}/stream/{mediaId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range != null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMedia(userId: string, token: string, files: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMedia(userId, token, files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.addMedia']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMediaFile(userId: string, mediaId: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMediaFile(userId, mediaId, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.deleteMediaFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {MediaFileCharacter} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaFile(userId: string, mediaId: string, type?: MediaFileCharacter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaFile(userId, mediaId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.getMediaFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.getMediaList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaListForUser(userId: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaListForUser(userId, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.getMediaListForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartialMediaFile(userId: string, mediaId: string, range?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartialMediaFile(userId, mediaId, range, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.getPartialMediaFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedia(userId: string, token: string, files: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.addMedia(userId, token, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaFile(userId: string, mediaId: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMediaFile(userId, mediaId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {MediaFileCharacter} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFile(userId: string, mediaId: string, type?: MediaFileCharacter, options?: any): AxiosPromise<string> {
            return localVarFp.getMediaFile(userId, mediaId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaList(options?: any): AxiosPromise<Array<MediaModel>> {
            return localVarFp.getMediaList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaListForUser(userId: string, token: string, options?: any): AxiosPromise<Array<MediaModel>> {
            return localVarFp.getMediaListForUser(userId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} mediaId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartialMediaFile(userId: string, mediaId: string, range?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPartialMediaFile(userId, mediaId, range, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {string} token 
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public addMedia(userId: string, token: string, files: Array<File>, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).addMedia(userId, token, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} mediaId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public deleteMediaFile(userId: string, mediaId: string, token: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).deleteMediaFile(userId, mediaId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} mediaId 
     * @param {MediaFileCharacter} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMediaFile(userId: string, mediaId: string, type?: MediaFileCharacter, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).getMediaFile(userId, mediaId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMediaList(options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).getMediaList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMediaListForUser(userId: string, token: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).getMediaListForUser(userId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} mediaId 
     * @param {string} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getPartialMediaFile(userId: string, mediaId: string, range?: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).getPartialMediaFile(userId, mediaId, range, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequestModel: CreateUserRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequestModel' is not null or undefined
            assertParamExists('createUser', 'createUserRequestModel', createUserRequestModel)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequestModel: CreateUserRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickUserModelExcludeKeyofUserModelToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequestModel} createUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequestModel: CreateUserRequestModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.createUser(createUserRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<PickUserModelExcludeKeyofUserModelToken> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserRequestModel} createUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(createUserRequestModel: CreateUserRequestModel, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(createUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }
}



