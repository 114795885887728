import './home.view.scss';

import { Configuration } from '@api/configuration';
import { createFromObservable } from '@codewyre/wyrekit-solid';
import { A } from '@solidjs/router';
import { concat, of, switchMap } from 'rxjs';

import { Image } from '../../../../components/images/image.view-model';
import { MediaService } from '../../../../services/media.service';
import { UserService } from '../../../../services/user.service';
import { useDependency } from '../../../../shared';

export function HomeView() {
  const configuration = useDependency<Configuration>(Configuration);
  const userService = useDependency<UserService>(UserService);
  const mediaService = useDependency<MediaService>(MediaService);
  const [user] = createFromObservable(userService.user);
  const [allImages] = createFromObservable(concat(
    of([]),
    mediaService
      .listChangedLastAt
      .pipe(
        //tap(() => setLoading(true)),
        switchMap(() => userService.user),
        switchMap(async (user) => {
          const mediaList = await mediaService.getAllFiles()
          return Promise.all(mediaList.map(async (media) => ({
            id: media.id,
            createdBy: media.createdBy,
            file: {
              type: media.type
            },
            url: Promise.resolve(
              `${configuration.basePath}/media/${media.createdBy.id}/${media.type.startsWith('video') ? 'stream' : 'download'}/${media.id}`
            ),
            thumbUrl: Promise.resolve(
              `${configuration.basePath}/media/${media.createdBy.id}/${media.type.startsWith('video') ? 'stream' : 'download'}/${media.id}?type=thumb'`
            )
          } as Image)))
        }))));

  // TODO: Reload images upon deletion

  let scrollTargetElement!: HTMLDivElement;

  return <div class="p-16 ta-center home">
    <div class="teaser glass">
      <img src="/assets/images/ela-micha.jpg" />
    </div>

    <div class="content glass">
      <p class="headline p-24">
        Hochzeit von <br />
        Daniela & Michael
      </p>

      <div>
        <p>Schön, dass du da bist!</p>
        <p>
          Hier kannst du uns deine Videos & Bilder von unserer Hochzeit hochladen.<br />

        </p>
        <p>Wir freuen uns über jede Erinnerung!</p>
        <div class="mb-24">
          <A href='/upload'>
            <button>Jetzt hochladen!</button>
          </A>
        </div>
      </div>
    </div>
  </div>
}