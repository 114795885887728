import './presentation.scss';

import { Module } from '@codewyre/wyrekit-composition';
import { RouterModule } from '@codewyre/wyrekit-composition-solid';
import { useDependencies } from '@codewyre/wyrekit-composition-solid-inversify';
import { RouteDefinition, Router } from '@solidjs/router';

import { Layout } from './layout.component';

export function Presentation() {
  const routes: RouteDefinition[] = locateRoutesInModules();

  return (<div class="presentation d-flex flex ai-center">
    <main class="d-flex ff-column">
      <Router root={Layout}>{routes}</Router>
    </main>
  </div>);
}

function locateRoutesInModules(): RouteDefinition[] {
  return useDependencies(Module)
    .map(x => x as RouterModule)
    .map(x => Object.getPrototypeOf(x).constructor as typeof RouterModule)
    .map(x => x.routes$)
    .reduce((prev, cur) => [...prev, ...(cur || [])], []);
}
