import { UsersApi } from '@api/api';
import { inject, injectable } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserModel } from '../models/user.model';

@injectable()
export class UserService {
  //#region Properties
  public static readonly  USER_STORAGE_KEY: string = 'user';

  private readonly _user = new BehaviorSubject(
    JSON.parse(localStorage.getItem(UserService.USER_STORAGE_KEY) || 'null') as UserModel | null);

  public get user(): Observable<UserModel|null> {
    return this._user;
  }
  public set user(value: UserModel|null) {
    this._user.next(value);
  }
  //#endregion

  //#region Constructor
  public constructor(
    @inject(UsersApi)
    private readonly _usersApi: UsersApi) { }
  //#endregion

  //#region Public Methods
  public async registerUser(desiredName: string): Promise<UserModel> {
    const result = await this._usersApi.createUser({
      name: desiredName
    });

    const { id, name, token } = result.data;
    const user = {
      id, name, token
    };
    this._user.next(user);

    localStorage.setItem(UserService.USER_STORAGE_KEY, JSON.stringify(user));
    return user;
  }

  public resetUser(): void {
    this.user = null;
    localStorage.removeItem(UserService.USER_STORAGE_KEY)
  }
  //#endregion
}