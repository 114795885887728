import { Accessor, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import { OverlayRequest } from '../../core/ui/overlay.service';

export function template(overlayItem: Accessor<OverlayRequest<any> | null>) {
  return <div class={"overlay " + (!!overlayItem() ? 'overlay--active' : '')}>
    <div class="overlay__wrapper">
      <Show when={overlayItem()}>
        <div class={"glass overlay__backdrop"}></div>
        <div class="overlay__content">
          <Dynamic
            component={overlayItem()!.component!}
            {...overlayItem()!.props} />
        </div>
      </Show>
    </div>
  </div>;
}