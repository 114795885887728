import './themes/base/index.scss';
import 'reflect-metadata';

import { HealthApi, MediaApi, UsersApi } from '@api/api';
import { BaseAPI } from '@api/base';
import { Configuration } from '@api/configuration';
import { Module } from '@codewyre/wyrekit-composition';
import { LogService } from '@codewyre/wyrekit-diagnostics';
import { application, BaseApp } from '@codewyre/wyrekit-runtime';
import { OverlayService } from '@codewyre/wyrekit-solid';
import axios from 'axios';
import { injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';
import { render } from 'solid-js/web';

import { OverlayComponent } from './components/overlay/overlay.component';
import { HomeModule } from './modules/home/home.module';
import { apiFactory } from './services/api.factory';
import { MediaService } from './services/media.service';
import { SecurityService } from './services/security.service';
import { UserService } from './services/user.service';
import { DependencyInjectionContext } from './shared';
import { Presentation } from './views/presentation/presentation';

const appConfiguration = new Configuration(
  await (await fetch('/configuration.json')).json());

@application({
  services: [
    UserService,
    MediaService,
    OverlayService,
    SecurityService
  ],
  factories: [
    { create: UsersApi, with: apiFactory(UsersApi, appConfiguration) },
    { create: MediaApi, with: apiFactory(MediaApi, appConfiguration) },
    { create: HealthApi, with: apiFactory(HealthApi, appConfiguration) },
    { create: Configuration, with: () => appConfiguration }
  ],
  aliases: [
    { use: HomeModule, withAlias: Module }
  ]
})
export class App extends BaseApp {
  public constructor() {
    const baseLogger = new LogService();

    super(baseLogger, baseLogger.createChild('App'));
  }

  public async spinUp(): Promise<void> {
    injectable()(BaseAPI);

    this
      .configureAxios()
      .buildContainer();

    try {
      await this.validateUser();
    } catch {

    }
  }

  public configureAxios(): this {
    axios.interceptors.request.use((config) => {
      const securityService = this.container.get(SecurityService);

      config.url = `${config.url}${(config.url || '').includes('?') ? '&' : '?'}passcode=${securityService.getPasscode()}`
      return config;
    }, (error) => Promise.reject(error));

    return this;
  }

  private async validateUser(): Promise<void> {
    const userService = this.container.get(UserService);
    const user = await firstValueFrom(userService.user);
    if (!user?.id) {
      return;
    }

    const usersApi = this.container.get(UsersApi);
    const { data: apiUser } = await usersApi.getUser(user.id);

    if (!apiUser) {
      userService.resetUser();
    }
  }

  public async main(): Promise<void> {
    await this.render();
  }

  private render(): App {
    const appContainer = document.getElementById("app");
    if (!appContainer) {
      throw new Error('Could not find #app container element in DOM.');
    }

    //  const Route = useRoutes(routes);
    // source={this._routerElectronIntegration()}
    render(
      () =>
        <DependencyInjectionContext.Provider value={this.container}>
          <Presentation />
          <OverlayComponent></OverlayComponent>
        </DependencyInjectionContext.Provider>
      ,
      appContainer);

    return this;
  }
}
