import './image-overlay.component.scss';
import './image-overlay.confirm-deletion.scss';

import { createFromObservable, OverlayService } from '@codewyre/wyrekit-solid';
import Bin from '@icons/bin.svg';

import { MediaService } from '../../../services/media.service';
import { UserService } from '../../../services/user.service';
import { useDependency } from '../../../shared';
import { ImageWrapper } from '../image-wrapper.component';
import { Image } from '../image.view-model';
import { ImageOverlayComponent, ImageOverlayProps } from './image-overlay.component';

export interface ImageOverlayConfirmDeletionComponentProps {
  image: Image;
  images: Image[];
  fallback: Image | null;
  onRemove?: (image: Image) => void;
}

export function ImageOverlayConfirmDeletionComponent(props: ImageOverlayConfirmDeletionComponentProps) {
  const image = () => props.image;
  const fallback = () => props.fallback;
  const images = () => props.images;

  const overlayService = useDependency<OverlayService>(OverlayService);
  const mediaService = useDependency<MediaService>(MediaService);
  const userService = useDependency<UserService>(UserService);
  const [user] = createFromObservable(userService.user);

  async function deleteImage(image: Image): Promise<void> {
    if (!image.id && props.onRemove) {
      props.onRemove(image);
      overlayService.close();
      return;
    }

    await mediaService.deleteFile(user()!, image)
    mediaService.triggerUpdate();
    if (!fallback()) {
      overlayService.close();
      return;
    }

    // TODO: Reload images;
    const newImages = images();
    const index = newImages.indexOf(image);
    if (index >= 0) {
      newImages.splice(index, 1);
    }

    overlayService.show<ImageOverlayProps>({
      component: ImageOverlayComponent,
      props: {
        image: fallback()!,
        imageList: newImages
      }
    });
  }

  return <div class="image-overlay image-overlay--confirmation ai-center d-flex jc-center ff-column g-16">
    <div class="flex"></div>
    <div class="d-flex message">
      <div class="ta-center">
        <h3>Bist du sicher?</h3>
        <h4>Die Datei wird entfernt!</h4>
      </div>
      <div class="p-16 ta-center">
        <ImageWrapper
          image={image()}
          showTypeIcon={false}
          isPreview={false}
          images={[]}/>
      </div>
      <div class="actions mt-32 d-flex jc-center g-16">
        <div class="ta-center">
          <button class="error" onClick={() => deleteImage(image())}>
            <Bin />Ja, Datei entfernen
          </button>
        </div>
        <div class="ta-center">
          <button onClick={() => overlayService.close()}>Abbrechen</button>
        </div>
      </div>
    </div>
    <div class="flex"></div>
  </div>
}