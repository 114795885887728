import './gallery.component.scss';

import { createFromObservable } from '@codewyre/wyrekit-solid';
import { useBeforeLeave, useLocation } from '@solidjs/router';
import { BehaviorSubject, concat, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { createSignal, For, JSX, onMount, Show } from 'solid-js';

import { Configuration } from '../../../../../../api/client/configuration';
import { ImageWrapper } from '../../../../components/images/image-wrapper.component';
import { MediaService } from '../../../../services/media.service';
import { SecurityService } from '../../../../services/security.service';
import { UserService } from '../../../../services/user.service';
import { useDependency } from '../../../../shared';

import type { Image } from '../../../../components/images/image.view-model';
export function Gallery() {
 const configuration = useDependency<Configuration>(Configuration);
  const userService = useDependency<UserService>(UserService);
  const mediaService = useDependency<MediaService>(MediaService);

  const location = useLocation();
  useBeforeLeave((e) => {
    if (e.from.pathname === (e.to as string).replace(/^([^\?]+).*/g, '$1')) {
      return;
    }

    document.body.classList.remove('pb-128');
    document.body.classList.remove('bg-black');
    document.body.classList.add('d-flex');
    document.body.querySelector('.presentation')?.classList.add('d-flex');
    document.body.querySelector('.presentation')?.classList.remove('h-auto');
  });

  onMount(() => {
    document.body.classList.remove('d-flex');
    document.body.classList.add('pb-128');
    document.body.classList.add('bg-black');
    document.body.querySelector('.presentation')?.classList.remove('d-flex');
    document.body.querySelector('.presentation')?.classList.add('h-auto');
  });

  const securityService = useDependency<SecurityService>(SecurityService);

  const [targetSize] = createSignal((screen.width - 110) / getSegmentsFor(screen.width));
  const cellSize = () => Math.floor((targetSize()) / 6);
  function getSpanEstimate(id: string, type: string,  size: number): number {
    //const result = Math.round(size / cellSize());
    if (size > ((cellSize() * 9) / 16)) {
      return Math.ceil((size) / cellSize());
    }
    return Math.ceil(size / 2 / cellSize());
    //return result;
  }

  const isAll$ = new BehaviorSubject(true);
  const [isAll] = createFromObservable(isAll$);
  const [user] = createFromObservable(userService.user);
  const [allImages] = createFromObservable(concat(
    of([]),
    mediaService
      .listChangedLastAt
      .pipe(
        //tap(() => setLoading(true)),
        switchMap(() => userService.user),
        switchMap(currentUser => isAll$
          .pipe(distinctUntilChanged())
          .pipe(map(isAll => ({isAll, currentUser})))),
        switchMap(async ({ isAll, currentUser  }) => {
          const mediaList = isAll
            ? await mediaService.getAllFiles()
            : await mediaService.getMyFiles(currentUser!);

          return Promise.all(mediaList.map(async (media) => {
            let width = media.stats.thumbnail?.width || targetSize();
            let height = media.stats.thumbnail?.height || targetSize();

            if (!media.stats.thumbnail) {
              height = media.stats.original.height!;
              width = media.stats.original.width!;
            }

            const max = Math.max(width, height);

            const heightWidthRatio = height / width;
            const widthHeightRatio = width / height;

            const target = targetSize();

            if (height === max) {
              width = target * widthHeightRatio;
              height = target;
            } else {
              height = target * heightWidthRatio;
              width = target;
            }

            return {
              id: media.id,
              createdBy: media.createdBy,
              file: {
                type: media.type
              },
              style: {
                'grid-column-end': `span ${getSpanEstimate(media.id, 'col', width)}`,
                'grid-row-end': `span ${getSpanEstimate(media.id, 'row', height)}`,
              } as JSX.CSSProperties,
              dimensions: {
                height, width
              },
              url: Promise.resolve(
                `${configuration.basePath}/media/${media.createdBy.id}/${media.type.startsWith('video') ? 'stream' : 'download'}/${media.id}?passcode=${securityService.getPasscode()}`
              ),
              thumbUrl: Promise.resolve(
                `${configuration.basePath}/media/${media.createdBy.id}/${media.type.startsWith('video') ? 'stream' : 'download'}/${media.id}?type=thumb&passcode=${securityService.getPasscode()}`
              )
            } as Image & {
              dimensions: { height: number;  width: number},
              style: { [index: string]: string }
            };
          }))
        }))));

  // TODO: Reload images upon deletion

  let scrollTargetElement!: HTMLDivElement;
  return <>
    <div class="gallery" style={{'--cell-size': `${cellSize()}px`}}>
      <For each={allImages()}>
        {image => <ImageWrapper
          image={image}
          style={image.style}
          isPreview={true}
          dimensions={image.dimensions}
          images={allImages()} />}
      </For>
    </div>
    <Show when={!allImages().length}>
      <div class="gallery__empty">
        <div class="gallery__empty__content">
          Noch keine Bilder & Videos verfügbar. Jetzt hochladen!
        </div>
      </div>
    </Show>
    <div class="glass gallery__top-bar d-flex shadow jc-center g-16 ai-center">
      <div
        class={"flex ta-center " + (isAll() ? 'active' : '')}
        onClick={() => isAll$.next(true)}>Alle Bilder</div>
      <Show when={user()?.id}>
        <div
          class={"flex ta-center " + (!isAll() ? 'active' : '')}
          onClick={() => isAll$.next(false)}>Meine hochgeladenen</div>
      </Show>
    </div>
  </>
}

function getSegmentsFor(width: number): number {
  if (width <= 475) {
    return 3;
  }

  if (width <= 835) {
    return 4;
  }

  if (width <= 1100) {
    return 5;
  }

  return Math.ceil(width / 256);
}
