import { routes } from '@codewyre/wyrekit-composition-solid';
import { injectable } from 'inversify';

import { Gallery } from './views/gallery/gallery.component';
import { HomeView } from './views/home/home.view';
import { Security } from './views/security/security.component';
import { UploadView } from './views/upload/upload.view';
import { UploadedView } from './views/uploaded/uploaded.view';

@injectable()
@routes({
    path: "security",
    component: Security,
  },
	{
		path: "gallery",
		component: Gallery,
	},
	{
		path: "uploaded",
		component: UploadedView,
	},
	{
		path: "upload",
		component: UploadView,
	},
	{
		path: "**",
		component: HomeView,
	},
)
export class HomeModule {}
