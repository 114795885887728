import { createFromObservable, OverlayService } from '@codewyre/wyrekit-solid';
import { RouteSectionProps, useBeforeLeave, useIsRouting, useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { createEffect } from 'solid-js';

import { NavigationBar } from '../../components/navigation-bar/navigation-bar.component';
import { SecurityService } from '../../services/security.service';
import { useDependency } from '../../shared';

export function Layout(props: RouteSectionProps) {
  const overlayService = useDependency<OverlayService>(OverlayService);
  const securityService = useDependency<SecurityService>(SecurityService);
  const currentPath = new BehaviorSubject<string|null>(null);
  const [distinctPath] = createFromObservable(currentPath.pipe(distinctUntilChanged()));
  const isRouting = useIsRouting();
  createEffect(() => {
    if (!isRouting()) {
      return;
    }

    const location = useLocation();

    currentPath.next(location.pathname);
  });

  const navigateTo = useNavigate();
  createEffect(async () => {
    const location = distinctPath();
    if (location?.startsWith('/security')) {
      return;
    }

    const [searchParams, setSearchParams] = useSearchParams();
    let { passcode } = searchParams;
    if (securityService.hasCode()) {
      passcode = passcode || securityService.getPasscode()!;
    }

    if (passcode) {
      const result = await securityService.validateAndStoreCode(passcode);

      if (result) {
        setSearchParams({ ...searchParams, passcode: undefined });
        return;
      }
    }

    navigateTo('/security');
  });


  useBeforeLeave(e => {
    if (e.from.pathname === (e.to as string).replace(/^([^\?]+).*/g, '$1')) {
      return;
    }

    overlayService.handleBeforeLeave(e);
  });

  return <>
    <>{props.children}</>
    <footer>
      <NavigationBar></NavigationBar>
    </footer>
  </>;
}