import './overlay.component.scss';

import { createFromObservable, OverlayService } from '@codewyre/wyrekit-solid';

import { useDependency } from '../../shared';
import { template } from './overlay.component.tpl';

export function OverlayComponent() {
  const overlayService = useDependency<OverlayService>(OverlayService);
  const [overlayItem] = createFromObservable(overlayService.overlayComponent);

  return template(overlayItem);
}