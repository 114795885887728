import './image-wrapper.component.scss';

import { createFromObservable, OverlayService } from '@codewyre/wyrekit-solid';
import Movie from '@icons/movie.svg';
import Star from '@icons/star.svg';
import { createResource, createSignal, JSX, Show } from 'solid-js';

import { UserService } from '../../services/user.service';
import { useDependency } from '../../shared';
import { LoadingIndicator } from '../loading-indicator/loading-indicator.component';
import { ImageOverlayComponent, ImageOverlayProps } from './image-overlay/image-overlay.component';
import { Image } from './image.view-model';

export interface ImageWrapperProps {
  image: Image,
  images: Image[],
  showTypeIcon?: boolean;
  isPreview?: boolean;
  showSize?: boolean;
  style?: JSX.CSSProperties;
  onRemove?: (image: Image) => void;
  dimensions?: { height: number; width: number };
}
export function ImageWrapper(props: ImageWrapperProps) {
  const image = () => props.image;
  const [imageUrl] = createResource(async () => props.isPreview
    ? await image().thumbUrl || await image().url
    : await image().url, {
      initialValue: ''
  });

  const [loading, setLoading] = createSignal(true);
  const showTypeIcon = () => props.showTypeIcon === undefined ? true : props.showTypeIcon;
  const isPreview = () => props.isPreview === undefined ? true : props.isPreview;

  function onShowMedia(element: HTMLImageElement | HTMLVideoElement) {
    function updateLoadingState() {
      if (!element.src) {
        return;
      }

      setLoading(false);
    };
    element.addEventListener("error", (...args: any[]) => {
      console.error(`Error loading`, args);
    });
    element.onload = () => updateLoadingState();
    element.addEventListener('loadeddata', updateLoadingState);
  };

  const overlayService = useDependency<OverlayService>(OverlayService);
  const userService = useDependency<UserService>(UserService);
  const [user] = createFromObservable(userService.user);

  function openPreview(image: Image) {
    if (!isPreview()) {
      return;
    }

    overlayService.show<ImageOverlayProps>({
      component: ImageOverlayComponent,
      props: {
        image,
        imageList: props.images,
        onRemove: props.onRemove,
        showSize: props.showSize

      }
    });
  }

  return <div class="image d-flex ai-center jc-center" onClick={() => openPreview(image())} style={props.style}>
    <div
      class={"image-wrapper d-flex flex jc-center ai-center " + (!isPreview() ? 'image-wrapper--full' : '')}
      style={props.dimensions ? {
          'min-height': `${props.dimensions.height}px`,
          'min-width': `${props.dimensions.width}px`
      } : {}}>
      <Show when={!image().createdBy || image().createdBy.id === user()?.id}>
        <Star class="star" />
      </Show>
      <Show when={image().file.type.startsWith('image/')}>
        <Show when={imageUrl()}>
          <img
            style={props.dimensions ? {
              'min-height': `${props.dimensions.height}px`,
              'min-width': `${props.dimensions.width}px`
            } : {}}
            loading="lazy"
            src={imageUrl()} ref={el => onShowMedia(el)} />
        </Show>
      </Show>
      <Show when={image().file.type.startsWith('video/')}>
        <Show when={showTypeIcon()}><Movie /></Show>
        <Show when={imageUrl()}>
          <Show when={isPreview()}>
            <video
             style={props.dimensions ? {
              'min-height': `${props.dimensions.height}px`,
              'min-width': `${props.dimensions.width}px`
              } : {}}
              src={imageUrl() + '#t=0.1'} playsinline preload="metadata" ref={el => onShowMedia(el)} />
          </Show>
          <Show when={!isPreview()}>
            <video
              style={props.dimensions ? {
                'min-height': `${props.dimensions.height}px`,
                'min-width': `${props.dimensions.width}px`
              } : {}}
              src={imageUrl() + '#t=0.1'}
              playsinline
              preload="metadata"
              controls
              ref={el => onShowMedia(el)} />
          </Show>
        </Show>
      </Show>
      <Show when={loading() || !imageUrl()}>
        <LoadingIndicator class="d-flex flex" />
      </Show>
    </div>
  </div>;
}
