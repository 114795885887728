import './upload.view.scss';

import { MediaService } from '@app/services/media.service';
import { UserService } from '@app/services/user.service';
import { ProgressInfo } from '@codewyre/wyrekit-runtime';
import { createFromObservable } from '@codewyre/wyrekit-solid';
import ArrowBackIcon from '@icons/arrow-back.svg';
import { A, useBeforeLeave } from '@solidjs/router';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { createSignal, Show } from 'solid-js';

import { Image } from '../../../../components/images/image.view-model';
import { Images } from '../../../../components/images/images.component';
import { LoadingIndicator } from '../../../../components/loading-indicator/loading-indicator.component';
import { SizeProgressBar } from '../../../../components/size-progress-bar/size-progress-bar';
import { useDependency } from '../../../../shared';

export function UploadView() {
  const userService = useDependency<UserService>(UserService);
  const photoService = useDependency<MediaService>(MediaService);

  const [user] = createFromObservable(userService.user);
  const [name, setName] = createSignal(user()?.name || '');
  const [canUpload, setCanUpload] = createSignal(true);

  let [progress, setProgress] = createSignal<ProgressInfo>({ current: 0, total: 0 });
  const [fileInput, setFileInput] = createSignal<HTMLInputElement | null>(null);
  const fileList$ = new BehaviorSubject<File[]>([]);
  const [fileList] = createFromObservable(fileList$);

  const [images] = createFromObservable(fileList$.pipe(map(fileList =>
    Array.from(fileList || []).map(file => ({
      file,
      createdBy: user(),
      size: file.size,
      url: Promise.resolve(window.URL.createObjectURL(file)),
      thumbUrl: Promise.resolve('')
    } as Image)))));

  async function addFiles(fileList: FileList) {
    const current = await firstValueFrom(fileList$);
    fileList$.next([
      ...current,
      ...Array.from(fileList)
    ]);
  }
  async function submitFiles() {
    if (!fileList() || !fileList()?.length) {
      return;
    }

    let currentUser = user();
    if (!currentUser || !currentUser.id) {
      currentUser = await userService.registerUser(name());
    }

    const { request, progress: requestProgress } = photoService.uploadFiles(currentUser, fileList()!);
    const subscription = requestProgress
      .subscribe(x => setProgress(x));
    await request;
    subscription.unsubscribe();
  }

  useBeforeLeave(e => {
    if (e.from.pathname === (e.to as string).replace(/^([^\?]+).*/g, '$1')) {
      return;
    }

    if (progress().total && progress().current < progress().total) {
      e.preventDefault();
      return;
    }
  });

  async function removeImage(image: Image): Promise<void> {
    const current = await firstValueFrom(fileList$);
    const index = current.indexOf(image.file as File);
    const newList = [...current];
    newList.splice(index, 1);
    fileList$.next(newList);
  }

  return <div class="upload p-16 ta-center d-flex ff-column flex">
    <div class="content p-32 glass  d-flex ff-column flex">
      <div>
        <A href="/">
          <div class="small-link d-flex ai-center no-font g-8 text-shadowed">
            <ArrowBackIcon /> Zurück
          </div>
        </A>
      </div>
      <div>
        <h3 class="text-bold">
          Bilder & Videos hochladen
        </h3>
      </div>
      <Show when={(fileList()?.length || 0) === 0}>
        <div class="d-flex ff-column jc-center flex text-shadowed flex ai-center">
          <p>
            Du kannst in einem Rutsch Dateien von insgesamt bis zu 500 MB hochladen.
          </p>
          <p>
            Du möchtest mehr hochladen? Kein Problem! Teile es gern einfach in zwei oder mehrere Male auf.
          </p>
        </div>
      </Show>
      <Show when={progress().total > 0}>
        <Show when={progress().current < progress().total}>
          <div class="text-shadowed">
            <p class="mt-48">
              <b>Dankeschön!</b>
            </p>
            <p class="mt-48">
              Bitte warte, bis deine Dateien vollständig hochgeladen wurden.
            </p>
            <p class="mt-24">
              Bis dahin dieses Fenster bitte <b><u>nicht schließen!</u></b>
            </p>
            <p>
              <LoadingIndicator />
            </p>
            <p>
              {parseFloat((progress().current / progress().total * 100) as any).toFixed(2)} % abgeschlossen
            </p>
          </div>
        </Show>
        <Show when={progress().current === progress().total}>
          <div class="mt-24 text-shadowed">
            <p>
              Erfolgreich, vielen Dank! Du kannst dieses Fenster nun
              schließen oder deine gespeicherten Bilder und Videos ansehen.
            </p>
            <p>
              <A href='/gallery'>
                <button class="secondary">Zur Gallerie</button>
              </A>
            </p>
          </div>
        </Show>
        <div class="flex"></div>
      </Show>

      <Show when={progress().total === 0}>
        <Show when={images().length}>
          <Images
            onRemove={image => removeImage(image)}
            images={images()}
            showSize={true} />
          <SizeProgressBar
            files={fileList()}
            maximum={500}
            onStatsUpdated={stats => setCanUpload(stats.used <= stats.maximum)}/>
        </Show>
        <Show when={user() == null}>
          <p>
            <input
              type="text"
              onInput={(ev) => setName(ev.target.value)}
              placeholder='Bitte gib deinen Namen ein...' />
          </p>
        </Show>
        <div class="d-flex g-8 ff-column">
          <button
            class={images().length ? "secondary" : 'primary'}
            disabled={!name()}
            onClick={() => fileInput()?.click()}>
            <div>
              <span>Fotos & Videos&nbsp;</span>
              <Show when={images().length}>
                <span>hinzufügen</span>
              </Show>
              <Show when={!images().length}>
                <span>auswählen</span>
              </Show>
            </div>
          </button>
          <Show when={images().length}>
            <button
              class='primary'
              disabled={!fileList()?.length || !canUpload()}
              onClick={() => submitFiles()}>Absenden</button>
          </Show>
        </div>
      </Show>
      <div class="d-none">
        <input
          onChange={(e) => addFiles(e.target.files)}
          ref={ref => setFileInput(ref)}
          type='file'
          accept="video/*,image/*"
          multiple />
      </div>
    </div>
  </div>
}