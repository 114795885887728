import { useDependency } from '@codewyre/wyrekit-composition-solid-inversify';
import { useNavigate, useSearchParams } from '@solidjs/router';

import { SecurityService } from '../../../../services/security.service';

export function Security() {
  const securityService = useDependency<SecurityService>(SecurityService);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateTo = useNavigate();

  async function validateAndStoreCore(code: string) {
    const result = await securityService.validateAndStoreCode(code);
    if (!result) {
      return;
    }

    const { redirectUri } = searchParams;

    let targetUri = '/';
    if (redirectUri) {
      setSearchParams({ ...searchParams, redirectUri: undefined });
      targetUri = redirectUri;
    }

    navigateTo(targetUri);
  }

  return <div class="glass p-24">
    <p>
      Der Zugriff auf diese Seite ist ohne gültigen Zugangscode nicht erlaubt!
    </p>
    <p>Bitte gib den Code manuell ein oder nutze den <b><u>QR-Code</u></b>, derbei der Veranstaltung geteilt wurde:</p>
    <div class="mt-32">
      <input
        type="text"
        onInput={(ev) => validateAndStoreCore(ev.target.value)}
        placeholder='Hier Zugangscode eingeben...' />
    </div>
  </div>;
}